import dayjs from 'dayjs';
import useSWR from 'swr-0-5-6';
import { apirc } from '~/configs/apirc';
import urlcat from 'urlcat';
import { useLatestTradeDateStore } from '~/modules/screener/containers/useLatestTradeDateStore';
const ROOT_URL = apirc.stock.stockScreenerURL;
export const ActionEnum = {
    buy: '買超',
    sell: '賣超',
};
export const WarrantTypeEnum = {
    call: '認購',
    put: '認售',
};
export const PowerEnum = {
    strong: '強勢',
    weak: '弱勢',
};
export const InstitutionEnum = {
    foreigner: '外資',
    trust: '投信',
    dealer: '自營商',
    dealer_hedge: '自營商(避險)',
    all: '全部',
};
export const CrossTypeEnum = {
    golden: '黃金',
    death: '死亡',
};
export const JoinEnum = {
    union: '聯集',
    intersection: '交集',
};
export const IndicatorEnum = {
    RGR_MODE_A: '紅黑紅-A式',
    RGR_MODE_B: '紅黑紅-B式',
    GRG: '黑紅黑',
    BIRD_MOUTH: '鳥嘴',
    REVERSE_BIRD_MOUTH: '反鳥嘴',
};
export const DailyRankEnum = {
    change_percent: '指定區間漲跌幅累積',
};
export const percentRangeStatusSeparator = ' - ';
export const PercentRangeStatusEnum = {
    new_high: `創新高${percentRangeStatusSeparator}當日高點創days區間的新高點 (>100%)`,
    new_low: `創新低${percentRangeStatusSeparator}當日高點創days區間中的新低點 (<0)`,
    near_high: `接近區間高點${percentRangeStatusSeparator}當日高點接近區間高點 (90%~100%)`,
    near_low: `接近區間低點${percentRangeStatusSeparator}當日低點接近區間低點 (0%~10%)`,
    both_high_low: `創高破底${percentRangeStatusSeparator}曾經碰過歷史高、低點`,
    normal: `區間震盪${percentRangeStatusSeparator}無`,
};
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 可取得的最近交易日統計資訊YYYY-MM-DD
 *
 * @deprecated
 */
export const useLatestTradeDate = () => {
    const res = useSWR(`${ROOT_URL}/latest_trade_date`, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    const date = res.data?.date;
    if (date)
        useLatestTradeDateStore.setState({ date });
};
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 可取得盤中交易 統計資訊YYYY-MM-DD
 *
 * @deprecated
 */
export const useIntraday = () => {
    const res = useSWR(`${ROOT_URL}/latest_trade_date?intraday=true`, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    const intraday = res.data?.date;
    if (intraday)
        useLatestTradeDateStore.setState({ intraday });
    return intraday;
};
/** 可取得的最近N日交易日統計資訊['YYYY-MM-DD',...] */
export const useLatestTradeDateSize = (options) => {
    const size = options?.size ?? 5;
    const url = urlcat(`${ROOT_URL}/latest_trade_date`, { size });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    });
    const date = res.data?.map(s => s.date);
    return date;
};
/** 換手率 */
export const useTurnoverRateResource = (options) => {
    const { volume_gte, volume_lte, amount_gte, amount_lte } = options;
    const date = options?.date ?? dayjs().format('YYYY-MM-DD');
    const threshold = options?.threshold ?? 0.1;
    const days = options?.days ?? 5;
    const url = urlcat(`${ROOT_URL}/turnover_rate`, {
        date,
        days,
        threshold,
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
    });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data?.sort((a, b) => b.turnover_rate - a.turnover_rate),
    };
};
/** 三大連續天數 買、賣 */
export const useBig3ConsecutiveResource = (options) => {
    const { volume_gte, volume_lte, amount_gte, amount_lte } = options;
    const { date, days, action, institution } = options;
    const url = urlcat(`${ROOT_URL}/big3_consecutive`, {
        date,
        days,
        action,
        institution,
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
    });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 爆量 */
export const useExtremeVolumeResource = (options) => {
    const { volume_gte, volume_lte, amount_gte, amount_lte, threshold } = options;
    const date = options?.date ?? dayjs().format('YYYY-MM-DD');
    const days = options?.days ?? 5;
    const url = urlcat(`${ROOT_URL}/extreme_volume`, {
        date,
        days,
        threshold,
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
    });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 強弱股票 */
export const useZScoreResource = (options) => {
    const { volume_gte, volume_lte, amount_gte, amount_lte } = options;
    const { date, days, type, limit } = options;
    const itemLimit = limit ?? 200;
    const url = urlcat(`${ROOT_URL}/z_score`, {
        date,
        days,
        type,
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
        limit: itemLimit,
    });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 即時排行 */
export const useDailyRankResource = (options, flag) => {
    const { date, volume_gte, volume_lte, amount_gte, amount_lte, order_by, sort_by, days, type, limit, } = options;
    const ll = urlcat(`${ROOT_URL}/daily_rank`, {
        date,
        order_by,
        sort_by,
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
        type,
        days,
        limit,
    });
    const res = useSWR([ll, flag], errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
    });
    return {
        data: res.data,
    };
};
/** 即時排行 */
export const useStkfutDailyRankResource = (options, flag) => {
    const { date, volume_gte, volume_lte, order_by, sort_by, days, type, limit } = options;
    const ll = urlcat(`${ROOT_URL}/stkfut_daily_rank`, {
        date,
        order_by,
        sort_by,
        volume_gte,
        volume_lte,
        type,
        days,
        limit,
    });
    const res = useSWR([ll, flag], errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 15000,
    });
    return {
        data: res.data,
    };
};
/** 熱門股票排行 */
export const usePopularResource = (options) => {
    const { sort, limit } = options;
    const date = options?.date ?? dayjs().format('YYYY-MM-DD');
    const sort_by = sort;
    const url = urlcat(`${ROOT_URL}/popular_stock`, { date, sort_by, limit });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** Kd值交叉 */
export const useKdCrossResource = (options) => {
    const { date, cross_type, fastK, slowK, slowD, days } = options;
    const url = urlcat(`${ROOT_URL}/kd_cross`, {
        date,
        cross_type,
        fastK,
        slowK,
        slowD,
        days,
    });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
export const useAdvanceDeclineResource = (date, flag) => {
    const url = urlcat(`${ROOT_URL}/advance_decline`, {
        date,
    });
    const res = useSWR(flag ? [url, flag] : url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 不帶入日期獲取最新漲跌家數相關資料 */
export const useAdvanceDeclineResource2 = () => {
    const url = urlcat(`${ROOT_URL}/advance_decline`, {});
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
        refreshInterval: 60 * 1000,
    });
    return {
        data: res.data,
    };
};
export const usePercentRangeResource = (options) => {
    const { volume_gte, volume_lte, amount_gte, amount_lte, days, status, date } = options;
    const url = urlcat(`${ROOT_URL}/percent_range`, {
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
        days,
        status,
        date,
    });
    const res = useSWR(options.days ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
export const useKabujukuResource = (options) => {
    const { date, join, indicators } = options;
    const url = urlcat(`${ROOT_URL}/kabujuku`, { date, join, indicators });
    const res = useSWR(options.indicators && options.join ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
export const useEdwinResource = (options) => {
    const { date } = options;
    const url = urlcat(`${ROOT_URL}/edwin`, { date });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
export const useWarrantChipResource = (options) => {
    const { date, action, warrantType } = options;
    const url = urlcat(`${ROOT_URL}/warrant_chip`, { date, bs: action, type: warrantType });
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 預估量 */
export const useEstimatedTotalVolume = (options) => {
    const { date, days, symbol } = options;
    const url = urlcat(`${ROOT_URL}/estimated_total_volume`, { date, days, symbol });
    const res = useSWR(options.symbol ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 連續收黑/收紅 */
export const useGetContinuousColor = (options) => {
    const { volume_gte, volume_lte, amount_gte, amount_lte } = options;
    const { days, date, rg, order_by } = options;
    const url = urlcat(`${ROOT_URL}/continuous_color`, {
        volume_gte,
        volume_lte,
        amount_gte,
        amount_lte,
        days,
        date,
        rg,
        order_by,
    });
    const res = useSWR(options.days ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 台股站上季線月線家數 */
export const useStockAboveMA = (options) => {
    const { date, days, ma, market } = options;
    const url = urlcat(`${ROOT_URL}/above_ma`, { date, days, ma, market });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** Winner98 */
export const useMarketLights = (options) => {
    const { date } = options;
    const url = urlcat(`${ROOT_URL}/market_lights`, { date });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** Sung op日線籌碼分數 */
export const useChipScore = (options) => {
    const { date } = options;
    const url = urlcat(`${ROOT_URL}/sung_chip_score`, { date });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return res.data;
};
/** daddy960 revive macd */
export const useReviveMACD = (options) => {
    const { date, bs } = options;
    const url = urlcat(`${ROOT_URL}/revive_macd`, { date, bs });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 雙均線狀態 type: 1~4 */
export const useDoubleMA = (options) => {
    const { date, ma1, ma2, bs, volume_gte, amount_gte } = options;
    const url = urlcat(`${ROOT_URL}/double_ma`, { date, ma1, ma2, bs, volume_gte, amount_gte });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 成交量雙均值比較 */
export const useDoubleVolumeMA = (options) => {
    const { date, ma1, ma2, volume_gte, amount_gte } = options;
    const url = urlcat(`${ROOT_URL}/double_volume_ma`, { date, ma1, ma2, volume_gte, amount_gte });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 集保大戶 */
export const useTdccStock = (options) => {
    const { date, bs, weeks_ago } = options;
    const url = urlcat(`${ROOT_URL}/tdcc_stock`, { date, bs, weeks_ago });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 持股 */
export const useFinanciaReport = (options) => {
    const { date, bs } = options;
    const url = urlcat(`${ROOT_URL}/financial_report`, { date, bs });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 近期N天平均成交量、成交值 */
export const useVolumeMa = (options) => {
    const { date, ma, volume_gte, amount_gte } = options;
    const url = urlcat(`${ROOT_URL}/volume_ma`, { date, volume_gte, amount_gte, ma });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
/** 近期N天大於N漲幅或小於N跌幅 */
export const useChangePercent = (options) => {
    const { date, days, percent_gte, percent_lte } = options;
    const url = urlcat(`${ROOT_URL}/change_percent`, { date, days, percent_gte, percent_lte });
    const res = useSWR(options.date ? url : null, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
